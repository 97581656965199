import Vue from 'vue'
import VueRouter from 'vue-router'
import Top from '../views/Top.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Top',
    component: Top
  },
  {
    path: '/brand',
    name: 'BrandList',
    component: () => import('../views/BrandList.vue')
  },
  {
    path: '/type',
    name: 'TypeNote',
    component: () => import('../views/TypeNote.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
