import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BIcon, BIconChevronUp, BIconCircle, BIconCircleFill } from 'bootstrap-vue'
import './assets/scss/custom.scss'
import axios from 'axios'
import VueAxios from 'vue-axios'


Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.component('BIcon', BIcon)
Vue.component('BIconChevronUp', BIconChevronUp)
Vue.component('BIconCircle', BIconCircle)
Vue.component('BIconCircleFill', BIconCircleFill)
Vue.use(VueAxios, axios)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
