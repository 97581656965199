import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    brands: null,
    brandArrayIndex: 0,
    types: null,
    typeArrayIndex: 0
  },
  mutations: {
    setBrands(state, src) {
      state.brands = src
    },
    setBrandProducts(state, src) {
      // console.log('setBrandProducts', src)
      for(let i=0; i<state.brands.length; i++) {
        if(state.brands[i].id == src.brandId) {
          state.brands[i].products = src.products
          break
        }
      }
    },
    setTypeProducts(state, src) {
      // console.log('setBrandProducts', src)
      for(let i=0; i<state.types.length; i++) {
        if(state.types[i].id == src.typeId) {
          state.types[i].products = src.products
          break
        }
      }
    },
    setTypes(state, src) {
      state.types = src
    },
    setTypeArrayIndex(state, src) {
      state.typeArrayIndex = src
    },

  },
  actions: {
    doSetBrands({commit}, src) {
      commit('setBrands', src)
    },
    doSetBrandArrayIndex({commit}, src) {
      commit('setBrandArrayIndex', src)
    },
    syncBrands({commit, state}, axios) {
      if (!state.brands) {
        axios.get(process.env.VUE_APP_API_BASE_URL + '/wp-json/wp/v2/brand?per_page=99&order=asc')
        .then(brands => {
          // console.log('brands', brands)
          for(let i=0; i<brands.data.length; i++) {
            brands.data[i].visible = false
          }
          commit('setBrands', brands.data)
          return new Promise(resolve => resolve())
        })
        .catch((e) => {
          alert(e);
          return new Promise(resolve => resolve())
        });
      }
    },
    syncBrandProducts({commit}, src) {
      console.log(src.brandId, src.axios)
      src.axios.get(process.env.VUE_APP_API_BASE_URL + '/wp-json/wp/v2/product?per_page=99&brand='+ src.brandId + '&_embed')
      .then(products => {
        // console.log('products', products)
        for(let i=0; i<products.data.length; i++) {
          products.data[i].visible = false
        }
        commit('setBrandProducts', {brandId: src.brandId, products: products.data})
        return new Promise(resolve => resolve())
      })
      .catch((e) => {
        alert(e);
        return new Promise(resolve => resolve())
      });
    },
    syncTypeProducts({commit}, src) {
      console.log(src.typeId, src.axios)
      src.axios.get(process.env.VUE_APP_API_BASE_URL + '/wp-json/wp/v2/product?per_page=99&type_note=' + src.typeId + '&_embed')
      .then(products => {
        // console.log('products', products)
        for(let i=0; i<products.data.length; i++) {
          products.data[i].visible = false
        }
        commit('setTypeProducts', {typeId: src.typeId, products: products.data})
        return new Promise(resolve => resolve())
      })
      .catch((e) => {
        alert(e);
        return new Promise(resolve => resolve())
      });
    },
    doSetTypes({commit}, src) {
      commit('setTypes', src)
    },
    doSetTypeArrayIndex({commit}, src) {
      commit('setTypeArrayIndex', src)
    },
    syncTypes({commit, state}, axios) {
      if (!state.types) {
        axios.get(process.env.VUE_APP_API_BASE_URL + '/wp-json/wp/v2/type_note/?per_page=99')
        .then(types => {
          // console.log(types.data)
          commit('setTypes', types.data)
          return new Promise(resolve => resolve())
        })
        .catch((e) => {
          alert(e);
          return new Promise(resolve => resolve())
        });
      }
    },
  },
  modules: {
  }
})
