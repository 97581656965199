<template>
  <div id="app">
    <nav class="row no-gutters nav-fixed border-bottom border-dark">
      <b-col cols="6">
        <b-button block :variant="isBrandList? 'dark': 'light'" size="lg" :to="{name: 'BrandList'}">BRANDLIST</b-button>
      </b-col>
      <b-col cols="6" class="border-left border-dark">
        <b-button block  :variant="isTypeNote? 'dark': 'light'" size="lg" :to="{name: 'TypeNote'}">TYPE &amp; NOTE</b-button>
      </b-col>
    </nav>
    <router-view/>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  data() {
    return {
      currentTab: 1
    }
  },
  mounted() {
    this.syncBrands(this.axios)
    this.syncTypes(this.axios)
  },
  computed: {
    isBrandList() {
      console.log(this.$route)
      return this.$route.name == 'BrandList'
    },
    isTypeNote() {
      return this.$route.name == 'TypeNote'
    },
  },
  methods: {
    ...mapActions({
      syncBrands: 'syncBrands',
      syncTypes: 'syncTypes',
    }),
  }
}
</script>

<style lang="scss">
.nav-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
}
</style>
