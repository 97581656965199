<template>
  <Vue100vh class="">
    <div class="h-100 d-flex flex-column w-100 align-items-center justify-content-center">
      <img style="width: 100px;" src="@/assets/images/bottle.svg"><br>
      <img style="width: 270px;" src="@/assets/images/logo-mobile.svg">
    </div>
  </Vue100vh>
</template>

<script>
import Vue100vh from 'vue-div-100vh'

export default {
  name: 'Top',
  components: {
    Vue100vh
  }
}
</script>
